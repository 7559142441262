import { httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取智能终端设备列表
export function GET_EQUIPMENT_TERMINAL_LIST(params: object) {
    return httpPost("/pole/equipment/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除智能终端设备
export function DELETE_EQUIPMENT_TERMINAL(params: object) {
    return httpPost("/pole/equipment/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 更新角度和加速度偏差
export function UPDATE_EQUIPMENT_TERMINAL(params: object) {
    return httpPost("/pole/equipment/update", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 角度锁定
export function LOCK_EQUIPMENT_TERMINAL_ANGLE(params: object) {
    return httpPost("/pole/equipment/angle/lock", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取视频直播
export function GET_LIVE_DATA(params: object) {
    return httpPost("/pole/equipment/generateSeed", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
